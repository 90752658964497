<template>
  <page-view :title="title">
    <a-card :bordered="false">
      <a-row :gutter="48">
        <a-col :md="3" :sm="24">
          <a-input placeholder="名称" v-model="queryParam.name" />
        </a-col>
        <a-col :md="3" :sm="24">
          <a-input placeholder="描述" v-model="queryParam.description" />
        </a-col>
        <a-col :md="4" :sm="0">
          <a-button class="editable-add-btn" @click="query">查询</a-button>
          <a-button
            style="margin-left: 8px;"
            class="editable-add-btn"
            @click="insert"
            >新增</a-button
          >
        </a-col>
      </a-row>
      <a-table
        style="margin-top: 10px;"
        rowKey="id"
        bordered
        :dataSource="dataSource"
        :columns="columns"
        :pagination="false"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <template slot="picture" slot-scope="text">
          <img
            :src="text"
            @click="() => (preview = { show: true, image: text })"
            style="width: 45px;height: 45px;"
          />
        </template>
        <template slot="operation" slot-scope="text, record">
          <a style="margin-left: 8px;" @click="update(record)">修改</a>
        </template>
      </a-table>
    </a-card>
    <a-modal
      title="编辑"
      :width="640"
      :visible="edit.show"
      v-if="edit.show"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <a-input class="top5" placeholder="名称" v-model="edit.name" />
      <a-input class="top5" placeholder="描述" v-model="edit.description" />
      <a-upload
        :multiple="false"
        :showUploadList="false"
        listType="picture"
        :customRequest="customRequest"
        accept="image/gif,image/png,image/jpeg,image/webp"
      >
        <img
          v-if="preview.image"
          :src="preview.image"
          style="width: 45px;height: 45px;"
        />
        <span v-else>上传主图</span>
      </a-upload>
    </a-modal>
    <a-modal
      :visible="preview.show"
      :footer="null"
      @cancel="() => (preview.show = false)"
    >
      <img style="width: 100%" :src="preview.image" />
    </a-modal>
  </page-view>
</template>
<script>
import { STable } from "@/components";
import { PageView } from "@/layouts";
import {
  goodsPartitionList,
  goodsPartitionInsert,
  goodsPartitionUpdate,
  filePreUpload,
  uploadOSS
} from "@api";
import { formatDate, uuid } from "@/utils/util";

export default {
  data() {
    return {
      preview: { image: "", show: false },
      edit: { show: false },
      queryParam: {},
      columns: [
        { title: "#", scopedSlots: { customRender: "serial" } },
        {
          title: "图片",
          dataIndex: "picture",
          scopedSlots: { customRender: "picture" }
        },
        { title: "名称", dataIndex: "name" },
        { title: "描述", dataIndex: "description" },
        {
          title: "创建时间",
          dataIndex: "createTime",
          customRender: formatDate
        },
        {
          title: "修改时间",
          dataIndex: "updateTime",
          customRender: formatDate
        },
        {
          title: "操作",
          dataIndex: "id",
          scopedSlots: { customRender: "operation" }
        }
      ],
      dataSource: []
    };
  },
  created() {
    this.query();
  },
  components: {
    PageView,
    STable
  },
  methods: {
    query() {
      goodsPartitionList(this.queryParam).then(res => {
        this.dataSource = res.data;
      });
    },
    update(record) {
      record.show = true;
      this.preview.image = record.picture;
      this.edit = JSON.parse(JSON.stringify(record));
    },
    insert() {
      this.edit = { show: true };
    },
    handleCancel() {
      this.edit = { show: false };
    },
    handleOk() {
      const edit = this.edit;
      edit.picture = this.preview.image;
      if (edit.id) {
        goodsPartitionUpdate(edit).then(res => {
          this.handleRes(res);
        });
      } else {
        goodsPartitionInsert(edit).then(res => {
          this.handleRes(res);
        });
      }
    },
    handleRes(res) {
      if (res.success) {
        this.$message.success("成功");
        this.query();
        this.edit = { show: false };
      } else {
        this.$message.error(res.msg);
      }
    },
    customRequest(fileData) {
      // TODO 1 上传到OSS
      filePreUpload()
        .then(response => {
          const data = response.data;
          const url = data.url;
          const prefix = data.key;
          delete data.url;
          delete data.key;
          const array = fileData.file.name.replace(" ", "").split("."); // 把文件名数组化
          let suffix = ""; // 后缀
          if (array.length > 1) {
            suffix = `.${array.pop().toLowerCase()}`;
          }
          const formData = new FormData();
          Object.keys(data).forEach(it => {
            formData.append(it, data[it]);
          });
          const key = `${prefix}/${uuid()}${suffix}`; // 图片访问路径
          formData.append("key", key);
          formData.append("file", fileData.file);
          uploadOSS(url, formData)
            .then(response => {
              // 上传成功，设置图片访问路径
              this.preview.image = "http://cache.wodh.cn/" + key;
              this.$message.success("上传成功.");
              return fileData.onSuccess(); // 上传成功了
            })
            .catch(error => {
              this.$message.error("上传异常." + error);
              return fileData.onError(); // 拒绝上传操作
            });
        })
        .catch(error => {
          this.$message.error("授权异常." + error);
          return fileData.onError(); // 拒绝上传操作
        });
    }
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style lang="less" scoped>
.top5 {
  margin-bottom: 8px;
}
</style>
